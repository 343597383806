<template>
  <Layout>
    <PageHeader :items="items" />
    <b-modal v-model="importModal" title="Import New Data" @ok="importQrData">
      <div v-if="isLoading == 1" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Please wait...</strong>
      </div>
      <div class="row">
        <div class="col-md-12 mb-2">
          <div class="file-upload-form">
            Choose File*
            <input
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              class="form-control"
              type="file"
              @change="getFile"
            />
          </div>
          <span class="text-muted"
            ><small
              >Note: Allowed file extensions : .csv,.xls,.xlsx . Max File Size
              Allowed : 2048 KB</small
            ></span
          >
        </div>
      </div>
    </b-modal>
    <!--FILTER START------>
    <div class="card" style="margin-top: -30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <!-- Country List-->
          <div class="col-md-2" style="width: 12%">
            <label>Country </label>
            <multiselect
              v-model="country"
              :options="countryArr"
              :show-labels="false"
              label="country"
              track-by="country"
              @input="getAllCities()"
            ></multiselect>
          </div>

          <div class="col-md-2" style="width: 12%">
            <label>City </label>
            <multiselect
              v-model="city"
              :options="cityArr"
              :show-labels="false"
              label="city"
              track-by="city"
              @input="getAllCompanies()"
            ></multiselect>
          </div>
          <div class="col-md-3">
            <label class="form-label" style="font-size: 12px"
              >Select Company
            </label>
            <multiselect
              v-model="companyID"
              :options="companyArr"
              :show-labels="false"
              label="companyName"
              track-by="companyID"
              @input="getRestaurantBrands()"
            ></multiselect>
          </div>
          <div class="col-md-2">
            <label class="form-label" style="font-size: 12px"
              >Select Restaurant Brand
            </label>
            <multiselect
              v-model="restID"
              :options="brandArr"
              :show-labels="false"
              label="name"
              track-by="restID"
              @input="getBranches()"
            ></multiselect>
          </div>
          <div class="col-md-3">
            <label>Restaurant Branch </label>
            <multiselect
              v-model="branchID"
              :options="restaurantBranches"
              :show-labels="false"
              label="branchName"
              track-by="restBranchID"
            ></multiselect>
          </div>
        </div>
        <div class="inner mb-2 row">
          <!-- Date range -->
          <div class="col-md-3">
            <label>Date Range </label>
            <date-picker
              v-model="daterange"
              append-to-body
              confirm
              format="DD MMM Y"
              lang="en"
              range
            ></date-picker>
          </div>

          <!-- Order Type END-->

          <!-- Apply filter -->
          <div class="col-md-3" style="width: auto; margin-top: auto">
            <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()">
              Apply Filter
            </button>
          </div>
          <!-- Apply filter END-->

          <!-- Clear filter -->
          <div class="col-md-3">
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
          <div class="col-md-3 mt-4">
            <button
              class="btn btn-themeYellow float-end"
              @click="importModal = !importModal"
            >
              <i class="uil-plus"></i> Import Data
            </button>
          </div>
          <!-- Clear filter END-->
        </div>
      </div>
    </div>
    <!--FILTER END------>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div v-if="loading == 1" class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading Please wait...</strong>
          </div>
          <b-tabs
            content-class="p-3 text-muted"
            justified
            nav-class="nav-tabs-custom"
          >
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block">PayU QR Sales</span>
              </template>
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div
                        class="col-sm-12 col-md-4"
                        style="margin-bottom: 5px"
                      >
                        <div class="btn-group" role="group">
                          <button
                            v-if="this.$storageData.profile.empTypeID == 1"
                            class="btn btn-themeBrown"
                            type="button"
                            @click="downloadSample()"
                          >
                            Download Excel
                          </button>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-4 ">
                        <div
                          id="tickets-table_length"
                          class="dataTables_length"
                        >
                          <label class="d-inline-flex align-items-center">
                            Show &nbsp;&nbsp;
                            <b-form-select
                              v-model="perPage"
                              :options="pageOptions"
                              size="sm"
                              style="margin-left: 5px; margin-right: 5px"
                              @input="getTransactions(1)"
                            ></b-form-select
                            >&nbsp;&nbsp;&nbsp; entries
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                      <b-table
                        :busy="isReportTableBusy"
                        :fields="fields"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        :items="tableItems"
                        :per-page="0"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        bordered
                        hover
                        outlined
                        responsive
                        striped
                      >
                        <template #table-busy>
                          <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                          </div>
                        </template>
                        <template v-slot:cell(transaction_id)="data">
                          <div>
                            <span>
                              {{ data.item.transaction_id }}
                            </span>
                          </div>
                        </template>

                        <template v-slot:cell(date)="data">
                          <span> {{ data.item.date }}</span>
                        </template>
                        <template v-slot:cell(payUID)="data">
                          <span> {{ data.item.payu_id }}</span>
                        </template>
                        <template v-slot:cell(amount)="data">
                          <span> {{ data.item.amount }}</span>
                        </template>
                        <template v-slot:cell(gst)="data">
                          <span> {{ data.item.gst }}</span>
                        </template>
                        <template v-slot:cell(status)="data">
                          <span> {{ data.item.status }}</span>
                        </template>
                        <template v-slot:cell(branchName)="data">
                          <span> {{ data.item.branchName }}</span>
                        </template>
                        <template v-slot:cell(customerName)="data">
                          <span> {{ data.item.customer_name }}</span>
                        </template>
                        <template v-slot:cell(customerEmail)="data">
                          <span> {{ data.item.customer_email }}</span>
                        </template>
                        <template v-slot:cell(customerPhone)="data">
                          <span> {{ data.item.customer_phone }}</span>
                        </template>
                        <template v-slot:cell(bankName)="data">
                          <span> {{ data.item.bank_name }}</span>
                        </template>
                        <template v-slot:cell(bankRefernceNo)="data">
                          <span> {{ data.item.bank_reference_no }}</span>
                        </template>
                        <template v-slot:cell(paymentType)="data">
                          <span> {{ data.item.payment_type }}</span>
                        </template>
                        <template v-slot:cell(field1)="data">
                          <span> {{ data.item.field_1 }}</span>
                        </template>
                        <template v-slot:cell(field4)="data">
                          <span> {{ data.item.field_4 }}</span>
                        </template>
                      </b-table>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div
                          class="
                            dataTables_paginate
                            paging_simple_numbers
                            float-end
                          "
                        >
                          <ul class="pagination pagination-rounded mb-0">
                            <b-pagination
                              v-model="currentPage"
                              :per-page="tableTo"
                              :total-rows="tableTotal"
                              size="md"
                            ></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config.json";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { authHeader } from "@/helpers/fakebackend/auth-header";

export default {
  name: "PayU_QR_SalesReport",
  page: {
    title: "QR Sales Reports",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      isReportTableBusy: false,
      tableData: [],
      tableTotal: "",
      tableTo: "",
      tableItems: [],
      tableExcelData: [],
      title: "PayU QR Sales",
      items: [
        {
          text: "View"
        },
        {
          text: "PayU QR Sales",
          active: true
        }
      ],
      importModal: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      daterange: [],
      restaurantBranches: [],
      branchID: "",
      cityArr: [],
      city: "",
      countryArr: [],
      country: "",
      companyArr: [],
      companyID: "",
      brandArr: [],
      restID: "",
      popupBranches: [],
      popupBranch: "",
      isLoading: 0,
      loading: 0,
      encodeDocument: "",
      fields: [
        {
          key: "transaction_id",
          sortable: true,
          tdClass: "align-right",
          label: "Transaction ID"
        },
        {
          key: "date",
          label: "Date",
          sortable: true
        },
        {
          key: "payUID",
          label: "PayU ID",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "amount",
          label: "Gross Amount",
          sortable: true
        },
        {
          key: "gst",
          label: "GST (5%)",
          sortable: true
        },
        {
          key: "status",
          label: "Status",
          sortable: true
        },
        {
          key: "branchName",
          label: "Restaurant Branch",
          sortable: true
        },
        {
          key: "customerName",
          label: "Customer Name",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "customerEmail",
          label: "Customer Email",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "customerPhone",
          label: "Customer Phone",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "bankName",
          label: "Bank Name",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "bankReferenceNo",
          label: "Bank Reference No",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "paymentType",
          label: "Payment Type",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "field1",
          label: "Field 1",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "field4",
          label: "Field 4",
          sortable: true,
          tdClass: "align-right"
        }
      ]
    };
  },
  mounted() {
    this.totalRows = this.items.length;
    this.getAllCountry();
  },
  computed: {
    rows() {
      return this.tableItems.length;
    }
  },
  watch: {
    currentPage: function(newVal) {
      this.viewSalesReportPerPage(newVal);
    }
  },
  methods: {
    getAllCountry() {
      this.loading = 1;
      this.countryArr = [];
      this.country = "";
      this.axios
        .post(this.$loggedRole+"/getAllCountry", {
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.loading = 0;
          this.countryArr = response.data.data;
        })
        .catch(error => {
          this.loading = 0;
          alert(error.response.data.message);
        });
    },
    getAllCities() {
      this.loading = 1;
      this.axios
        .post(this.$loggedRole+"/getAllCities", {
          country: this.country ? this.country.country : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.loading = 0;
          this.cityArr = response.data.data;
        })
        .catch(error => {
          this.loading = 0;
          alert(error.response.data.message);
        });
    },
    getAllCompanies() {
      this.loading = 1;
      this.companyArr = [];
      this.companyID = "";
      this.axios
        .post(this.$loggedRole+"/getAllCompanies", {
          country: this.country ? this.country.country : "",
          city: this.city ? this.city.city : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.loading = 0;
          this.companyArr = response.data.data;
        })
        .catch(error => {
          this.loading = 0;
          alert(error.response.data.message);
        });
    },
    getRestaurantBrands() {
      this.loading = 1;
      this.axios
        .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", {
          companyID: this.companyID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.loading = 0;
          this.brandArr = response.data.data;
        })
        .catch(error => {
          this.loading = 0;
          alert(error.response.data.message);
        });
    },
    getBranches() {
      if (this.country.length == 0 || this.country == "undefined") {
        alert("Please select country.");
      } else if (this.city.length == 0 || this.city == "undefined") {
        alert("Please select city.");
      } else if (this.restID.length == 0 || this.restID == "undefined") {
        alert("Please select restaurant brand.");
      } else {
        this.loading = 1;
        this.axios
          .post(this.$loggedRole+"/qr-sales/get-restaurant-branch", {
            restaurant_id: this.restID.restID,
            empTypeID: this.$storageData.profile.empTypeID,
            city: this.city ? this.city.city : "",
            country: this.country ? this.country.country : "",
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type
          })
          .then(response => {
            this.loading = 0;
            if (response.data.status == 200) {
              this.restaurantBranches = response.data.data;
            } else {
              alert(response.data.message);
            }
          })
          .catch(error => {
            this.loading = 0;
            alert(error.response.data.message);
          });
      }
    },
    getFile: function(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        this.encodeDocument = input.files[0];
      }
    },
    importQrData(e) {
      e.preventDefault();
      this.isLoading = 1;
      let formData = new FormData();
      formData.append("uploaded_doc", this.encodeDocument);
      formData.append("employee_id", this.$storageData.profile.pid);
      let headers = authHeader();
      headers["Content-Type"] = "multipart/form-data";
      this.axios
        .post(this.$loggedRole+"/qr-sales/import-data", formData, { headers })
        .then(response => {
          this.isLoading = 0;
          this.importModal = !this.importModal;
          if (response.data.status == 200) {
            alert(response.data.message);
          } else {
            alert(response.data.message);
          }
        })
        .catch(error => {
          this.isLoading = 0;
          alert(error.response.data.message);
        });
    },
    applyFilter() {
      this.getTransactions(1);
    },
    viewSalesReportPerPage(currentPage) {
      this.getTransactions(currentPage);
    },
    clearFilter() {
      this.tableData = [];
      this.tableTotal = "";
      this.tableTo = "";
      this.tableItems = [];
      this.filterOn = [];
      this.daterange = [];
      this.restaurantBranches = [];
      this.branchID = "";
      this.cityArr = [];
      this.city = "";
      this.country = "";
      this.companyArr = [];
      this.companyID = "";
      this.brandArr = [];
      this.restID = "";
      this.tableExcelData = [];
    },
    getTransactions(page) {
      this.loading = 1;
      this.isReportTableBusy = true;
      this.axios
        .post(this.$loggedRole+"/qr-sales/get-qr-transactions?page=" + page, {
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          restaurant_branch_id:
            this.branchID !== null ? this.branchID.restBranchID : "",
          daterange: this.daterange,
          loginTypeID: this.$storageData.login_type,
          perPage: this.perPage,
          country: this.country ? this.country.country : "",
          city: this.city ? this.city.city : ""
        })
        .then(response => {
          this.loading = 0;
          this.isReportTableBusy = false;
          if (response.data.status == 200) {
            if (this.perPage != "All") {
              if (page === 1) {
                this.tableData = response.data;
                this.tableTotal = this.tableData.data.total;
                this.tableTo = this.tableData.data.to;
              }
              this.tableExcelData = this.tableItems = response.data.data.data;
            } else {
              this.tableExcelData = this.tableItems = response.data.data;
              this.tableTotal = 1;
              this.tableTo = 1;
            }
          } else {
            alert(response.data.message);
          }
        })
        .catch(error => {
          this.loading = 0;
          this.isReportTableBusy = false;
          alert(error.response.data.message);
        });
    },
    downloadSample() {
      this.isLoading = 1;
      this.axios
        .post(this.$loggedRole+"/exportExcel",
          {
            exportData: this.tableExcelData,
            page: "PayUQRSales"
          },
          {
            responseType: "blob"
          }
        )
        .then(response => {
          this.isLoading = 0;
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "payu-qr-sales.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    }
  }
};
</script>

<style scoped></style>
